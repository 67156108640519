<template>
  <div class="uk-padding uk-background-muted" style="min-height: 70vh">
    <div >
      <h1 class="uk-text-center  " style=" color: #FAA21B;"><span>Tax Law</span></h1>
    </div>
    <div class="uk-padding">
      <div class="uk-container">
        <div class="uk-panel">
          <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="/visionpic.jpg"  alt="Example image">
          <h4>The areas under tax law that we cover are: </h4>
          <ul class="uk-list uk-list-bullet">
            <li><span class="bluec">Unpaid Taxes</span></li>
            <li><span class="bluec">Tax Lien</span></li>
            <li><span class="bluec">Tax Penalties</span></li>
            <li><span class="bluec">Tax Levy</span></li>
            <li><span class="bluec">Fresh Start Program</span></li>
            <li><span class="bluec">Currently Not Collectible</span></li>
            <li><span class="bluec">Innocent Spouse</span></li>
            <li><span class="bluec">Penalty & Interest Abatement</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "TaxLaw"
}
</script>

<style scoped>
dt{
  color: #093170;

  font-size: large;
}
.bluec{
  font-weight: bold;
  font-size: 16px;
  color: #093170;
}

@media only screen and (min-width : 320px) {
  img{
    max-width: 280px;
    max-height: 280px;
    width: auto;
    height: auto;
    border-radius: 5px;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  img{
    max-width: 320px;
    max-height: 320px;
    width: auto;
    height: auto;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  img{
    max-width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

@media only screen and (min-width : 1400px) {

}
@media only screen and (min-width : 1600px) {

}
</style>