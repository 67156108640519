<template>
    <div class="uk-container uk-padding uk-visible@m" id="affiliates">
        <!--<h1 class="uk-text-center uk-text-bold " style="font-variant: all-small-caps; color: rgba(13,71,161 ,1)">Affiliates</h1>-->
        <div class="uk-container uk-container-small">
        <div class=" uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l" uk-grid uk-scrollspy="cls: uk-animation-slide-bottom; target: > div; delay: 300; ">
            <div uk-scrollspy-class="uk-animation-slide-bottom uk-margin-top">
                <img src="../../assets/star.png" alt="" style="max-width: 130px; max-height: 130px; height: auto; width: auto;">
            </div>
            <div uk-scrollspy-class="uk-animation-slide-bottom">
                <img src="../../assets/gleaner.png" alt="" style="max-width: 130px; max-height: 130px; height: auto; width: auto;">
            </div>
            <div uk-scrollspy-class="uk-animation-slide-bottom">
                <img src="../../assets/observer.png" alt="" style="max-width: 130px; max-height: 130px; height: auto; width: auto;">
            </div>
            <div uk-scrollspy-class="uk-animation-slide-bottom">
                <img src="../../assets/tvj.png" alt="" style="max-width: 100px; max-height: 100px; height: auto; width: auto;">
            </div>

        </div>
            <p class="uk-text-small uk-text-center">These should be notable recognitions if any</p>
    </div>
    </div>
</template>

<script>
    export default {
        name: "affiliateAwardsRecognition"
    }
</script>

<style scoped>
    img{
        max-width: 100px;
        max-height: 100px;
        height: auto;
        width: auto;
    }
</style>