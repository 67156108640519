<template>
  <div id="app">
    <navbar/>
    <router-view/>
    <foot/>
  </div>
</template>

<style>

</style>
<script>
  import Navbar from "./components/shared/navbar";
  import Foot from "./components/shared/foot";
  export default {
    components: {Foot, Navbar}
  }
</script>