<template>
    <div class="uk-padding" id="services">
        <h1 class="uk-text-center  uk-heading-line" style="font-variant: all-small-caps; color: #FAA21B;"><span>Areas of Practice</span></h1>
        <div class="uk-container uk-container-small">
            <div uk-grid>
                <div class="uk-width-auto@m">

                    <ul class="uk-nav uk-nav-default" uk-switcher="connect: #component-nav; animation: uk-animation-fade" id="servicesList">
                        <li><a href="#">Family Law<span uk-icon="chevron-right"></span></a></li>
                        <li><a href="#">Immigration Law<span uk-icon="chevron-right"></span></a></li>
                        <li><a href="#">Tax Law<span uk-icon="chevron-right"></span></a></li>
                    </ul>

                </div>
                <div class="uk-width-expand@m">

                    <ul id="component-nav" class="uk-switcher">
                        <li>
                            <div class="uk-child-width-1-2@m" uk-grid>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Child Custody/Time-Sharing</dt>
                                        <dd></dd>
                                        <dt>Child Support</dt>
                                        <dd></dd>

                                    </dl>
                                </div>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Divorce</dt>
                                        <dd></dd>
                                        <dt>Paternity</dt>
                                        <dd></dd>

                                    </dl>
                                </div>
                            </div>
                            <router-link to="/family_law" class="uk-button uk-button-secondary uk-button-small uk-align-right@m uk-text-capitalize" style="background-color: #093170">More ...</router-link>
                        </li>

                        <li>
                            <div class="uk-child-width-1-2@m" uk-grid>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Family Based Immigration</dt>
                                        <dd></dd>
                                        <dt>Citizenship and Naturalization</dt>
                                        <dd> </dd>


                                    </dl>
                                </div>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Humanitarian Visas (VAWA)</dt>
                                        <dd></dd>
                                        <dt>Visitors and Counselor Visas</dt>
                                        <dd></dd>


                                    </dl>
                                </div>
                            </div>
                            <router-link to="/immigration_law" class="uk-button uk-button-secondary uk-button-small uk-align-right@m uk-text-capitalize" style="background-color: #093170">More ...</router-link>
                        </li>
                        <li>
                            <div class="uk-child-width-1-2@m" uk-grid>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Unpaid Taxes</dt>
                                        <dd></dd>
                                        <dt>Penalty & Interest Abatement</dt>
                                        <dd></dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl class="uk-description-list uk-description-list-divider">
                                        <dt>Tax Lien</dt>
                                        <dd></dd>
                                        <dt>Tax Penalties</dt>
                                        <dd></dd>
                                    </dl>
                                </div>
                            </div>
                            <router-link to="/tax_law" class="uk-button uk-button-secondary uk-button-small uk-align-right@m uk-text-capitalize" style="background-color: #093170">More ...</router-link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ourServices"
    }
</script>

<style scoped>
    #servicesList a{
        font-size: large;
        padding: 5px 15px 5px 15px;
        color: #093170;
    }
    #servicesList .uk-active{
        font-size: large;
        padding: 5px 15px 5px 15px;
        background-color: #f8f8f8;
    }

    dd{
        font-size: small;
    }
    dt{
        font-size: medium;
        font-weight: bold !important;
        color: teal !important;
    }
</style>