import { render, staticRenderFns } from "./clientTestimonials.vue?vue&type=template&id=2e9b75eb&scoped=true&"
import script from "./clientTestimonials.vue?vue&type=script&lang=js&"
export * from "./clientTestimonials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9b75eb",
  null
  
)

export default component.exports