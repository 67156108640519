<template>
  <div class="uk-padding uk-background-muted" uk-scrollspy="cls:uk-animation-fade">
    <div class="uk-container">
      <h1 class="uk-heading-line" style="font-variant: all-small-caps; color: #FAA21B;"><span>Five Minutes with Michelle Fanger, Esq</span></h1>
      <div class="uk-child-width-1-2@m uk-child-width-1-4@l" uk-grid>
        <div>
          <iframe width="100%" height="280" src="https://www.youtube.com/embed/JXUXcMixyLI"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div>
          <iframe width="100%" height="280" src="https://www.youtube.com/embed/a6IocXDeDZ8"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div>
          <iframe width="100%" height="280" src="https://www.youtube.com/embed/gNSiFNcw2bI"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div>
          <iframe width="100%" height="280" src="https://www.youtube.com/embed/wbgVxBYCjQI"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      </div>
<!--
      <a href="https://www.youtube.com/channel/UCXAejC-hzx6dh3qf5Cb2kAA" target="_blank" class="uk-icon-button uk-margin-small-right" ></a>
-->
      <br> <br>
      <a href="https://www.youtube.com/channel/UCXAejC-hzx6dh3qf5Cb2kAA" target="_blank" class="uk-button uk-button-default uk-align-right@s uk-text-capitalize">
        <span uk-icon="youtube" style="color: #bb0000;"></span>
         Subscribe to my channel</a>
    </div>
  </div>
</template>

<script>
export default {
name: "fiveMinutes"
}
</script>

<style scoped>

</style>