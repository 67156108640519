import Vue from 'vue'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

import Home from '../views/Home.vue'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import 'uikit/dist/css/uikit.min.css'
import About from "../views/About";
import ContactUs from "../views/ContactUs";
import PracticeAreas from "../views/PracticeAreas";
import PeopleCommunity from "../views/PeopleCommunity";
import ImmigrationLaw from "../views/ImmigrationLaw";
import FamilyLaw from "../views/FamilyLaw";
import Testimonials from "@/views/Testimonials";
import TaxLaw from "@/views/TaxLaw";
// loads the Icon plugin
UIkit.use(Icons);

Vue.use(VueRouter)

  const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About Our Firm', component: About },
  { path: '/contactus', name: 'Contact Us', component: ContactUs },
  { path: '/practiceareas', name: 'Practice Areas', component: PracticeAreas },
  { path: '/peopleandcommunity', name: 'People And Community', component: PeopleCommunity },
  { path: '/immigration_law', name: 'Immigration Law', component: ImmigrationLaw },
  { path: '/family_law', name: 'Family Law', component: FamilyLaw },
  { path: '/tax_law', name: 'Tax Law', component: TaxLaw },
  { path: '/testimonials', name: 'Client Testimonials', component: Testimonials },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
