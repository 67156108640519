<template>
    <div class="uk-padding uk-background-muted">

        <br>
        <div class="uk-container">

            <div class="uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-3@l uk-margin-large-left@m uk-margin-large-right@m" uk-grid>
                <div style="padding-left: 15px !important;">
                    <div class=" ">
                        <h3 class="uk-heading-divider uk-text-center makeyellow">Active Cases</h3>
                        <p class="makeyellow uk-text-center uk-heading-small">35</p>
                        <br>

                    </div>
                </div>
                <div style="padding-left: 15px !important;">
                    <div class=" ">

                        <h3 class="uk-heading-divider uk-text-center makeyellow ">Convictions</h3>
                        <p class="makeyellow uk-text-center uk-heading-small">709</p>
                        <br>

                    </div>
                </div>
                <div style="padding-left: 15px !important;">
                    <div class=" ">
                        <h3 class="uk-heading-divider uk-text-center makeyellow">Open Cases</h3>
                        <p class="makeyellow uk-text-center uk-heading-small">45</p>
                        <br>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Numbers"
    }
</script>

<style scoped>
p{
    color: #FAA21B;
}
</style>