<template>
    <div class="uk-padding" style="background-color: #093170; border-top: #FAA21B 1px solid;">
        <div class="uk-container">
            <div class="uk-child-1-1@m" grid>
                <div>
                    <div class="uk-text-center">
                        <a class=" uk-logo" style="color: #FAA21B" href="#">
                            <img src="../../../public/logo.png"  alt="">
                        </a>

                    </div>
                </div>
                <div  class="uk-text-center uk-text-small uk-light">
                    <br>
                    <a href="tel:+19044806612">Phone: +1 904-480-6612</a>
                    <br>

                </div>
                <div class="uk-text-center uk-text-small uk-light" >
                    <p>
                        <a href="mailto:michelle.t.fanger@gmail.com">Email: michelle.t.fanger@gmail.com</a>
                    </p>
                </div>
                <div>
                    <div  uk-grid>
                        <div class="uk-align-center uk-text-center">
                            <p class="uk-text-center uk-text-small uk-text-muted">Find Us | Like Us</p>
                            <div >
                                <a href="https://twitter.com/LawFanger" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="twitter" style="color: #00aced;"></a>

                                <a href="https://www.facebook.com/mfangerlaw" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook" style="color: #3b5998;"></a>

                                <!-- <a href="https://plus.google.com/106251138258617752932" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="google-plus" style="color: #dd4b39;"></a> -->

                                <a href="https://www.instagram.com/fangerlawfirm/" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="instagram" style="color: #3b5998;"></a>

                                <!-- <a href="https://www.flickr.com/photos/72113266@N04/" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="flickr" style="color: #ff0084;"></a>

                                <a href="https://jm.linkedin.com/school/northern-caribbean-university/" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="linkedin" style="color: #007bb6;"></a> -->

                                <a href="https://www.youtube.com/channel/UCXAejC-hzx6dh3qf5Cb2kAA" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="youtube" style="color: #bb0000;"></a>
                            </div>
                        </div>


                    </div>
                </div>
                <!-- <div class="uk-text-center uk-text-small" style="color: #FAA21B">Copyright Fanger Law Firm 2020</div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "foot"
    }
</script>

<style scoped>
    @media only screen and (min-width : 320px) {
        .uk-logo img{
            max-width: 130px;
            max-height: 130px;
            height: auto;
            width: auto;
        }
    }
</style>