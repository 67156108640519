<template>
    <div>
        <div class="uk-padding uk-background-muted">
            <div class="uk-container uk-container-small">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1@m" style="border-radius: 15px">
                    <a class="uk-navbar-item uk-logo" href="#" >
                        <img src="../../public/logo_horizontal.png"  alt="">
                    </a>

                    <p class="uk-text-center">
                        6821 Southpoint Drive Ste. 229<br />
                        Jacksonville, FL 32216<br />
                        <a href="tel:+19044806612">Phone: +1 904-480-6612</a> <br>
                        <a href="mailto:michelle.t.fanger@gmail.com">Email: michelle.t.fanger@gmail.com</a>
                    </p>
                    <div  uk-grid>
                        <div class="uk-align-center uk-text-center">
                            <p class="uk-text-center">Find Us | Like Us</p>
                            <div >
                                <a href="https://twitter.com/LawFanger" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="twitter" style="color: #00aced;"></a>

                                <a href="https://www.facebook.com/mfangerlaw" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook" style="color: #3b5998;"></a>

                                <!-- <a href="https://plus.google.com/106251138258617752932" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="google-plus" style="color: #dd4b39;"></a> -->

                                <a href="https://www.instagram.com/fangerlawfirm/" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="instagram" style="color: #3b5998;"></a>

                                <!-- <a href="https://www.flickr.com/photos/72113266@N04/" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="flickr" style="color: #ff0084;"></a>

                                <a href="https://jm.linkedin.com/school/northern-caribbean-university/" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="linkedin" style="color: #007bb6;"></a> -->

                                <a href="https://www.youtube.com/channel/UCXAejC-hzx6dh3qf5Cb2kAA" target="_blank" class="uk-icon-button uk-margin-small-right" uk-icon="youtube" style="color: #bb0000;"></a>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="uk-padding">
            <div class="uk-container uk-container-small">
                <form @submit.prevent="validateForm('contactForm')"  data-vv-scope="contactForm">
                    <fieldset class="uk-fieldset">

                        <legend class="uk-legend">We are waiting to help.</legend>

                        <div class="uk-margin">
                            <label for="" class="uk-text-small">Full Name</label>
                            <input class="uk-input" type="text" placeholder="John Doe" v-validate="'required'" v-model="name" name="name">
                            <span class="uk-text-small uk-text-danger">{{ errors.first('contactForm.name') }}</span>
                        </div>
                        <div class="uk-margin">
                            <label for="" class="uk-text-small">Email</label>
                            <input class="uk-input" type="text" placeholder="johndoe@email.com" v-validate="'required|email'" v-model="email" name="email">
                          <span class="uk-text-small uk-text-danger">{{ errors.first('contactForm.email') }}</span>
                        </div>
                        <!--<div class="uk-margin">
                              <label for="" class="uk-text-small">Subject</label>
                              <input class="uk-input" type="text" placeholder="Subject" >

                        </div>-->

                        <div class="uk-margin">
                            <label for="" class="uk-text-small">Practice Area</label>
                            <select class="uk-select" v-validate="'required'"  v-model="subject" name="subject">
                                <!--<option disabled selected="selected">Choose Area</option>-->
                                <option>Family Law</option>
                                <option>Immigration Law</option>
                                <option>Tax Law</option>
                            </select>
                            <span class="uk-text-small uk-text-danger">{{ errors.first('contactForm.subject') }}</span>
                        </div>


                        <div class="uk-margin">
                            <label for="" class="uk-text-small">Message/Enquiry</label>
                            <textarea class="uk-textarea" rows="5" placeholder="Message" v-validate="'required'" v-model="message" name="message"></textarea>
                          <span class="uk-text-small uk-text-danger">{{ errors.first('contactForm.message') }}</span>
                        </div>

                        <!--<div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <label><input class="uk-checkbox" type="checkbox" checked> A</label>
                            <label><input class="uk-checkbox" type="checkbox"> B</label>
                        </div>-->
                        <div class="uk-align-right@m">
                            <button type="submit" class="uk-button uk-button-primary uk-text-capitalize">Send</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        <div>
            <div class=" uk-background-muted uk-padding">
                <div class="">
                    <h2 class="uk-heading-line uk-text-center">Find Us</h2>                    
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3446.411155189208!2d-81.59117473681293!3d30.253864291023465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5ca08565eb481%3A0xd72fefa7589498c1!2s6821%20Southpoint%20Dr%20S%20%23229%2C%20Jacksonville%2C%20FL%2032216%2C%20USA!5e0!3m2!1sen!2sjm!4v1647397615980!5m2!1sen!2sjm" 
                            style="width:100%; height:450px; border:0;" allowfullscreen="" loading="lazy" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UIkit from'uikit'
    export default {
        name: "ContactUs",
        data(){
          return {
            name: '',
            email: '',
            message:'',
            subject: ''
          }
        },
      methods: {
        validateForm(scope) {
          this.$validator.validateAll(scope).then((result) => {
            if (result) {
              // eslint-disable-next-line

              //alert('Form Submitted!');
            //   let formData = new FormData();
            //   formData.append('Name', this.name);
            //   formData.append('SenderEmail', this.email);
            //   formData.append('Message', this.message);
            //   formData.append('Subject', this.subject);
            //   formData.append('RecipientEmail', 'renaudchin@gmail.com');
              
                //using a json object instead of form data
                var data = {        
                    Name: this.name,
                    SenderEmail: this.email,
                    RecipientEmail: 'michelle.t.fanger@gmail.com',
                    Subject: this.subject,
                    Message: this.message
                };
              //console.log(this.subject)
              this.$store.dispatch("contactForm", data
                  //token: this.$session.get('jwt')
              ).then(response =>{
                this.name = ''
                this.email = ''
                this.message = ''
                this.subject = ''
                this.$validator.reset()
                UIkit.notification({
                  message: 'Your message was successfully sent.',
                  status: 'primary',
                  pos: 'top-right',
                  timeout: 5000
                });
              }).catch(error =>{
                UIkit.notification({
                  message: 'Something went wrong, please try calling our office or sending an email to the email address listed.',
                  status: 'danger',
                  pos: 'top-right',
                  timeout: 5000
                });
              })
            }
          });
        }
      }
    }
</script>

<style scoped>
    /* Custom, iPhone Retina */
    @media only screen and (min-width : 320px) {
        .c_image{
            max-width: 200px;
            max-height: 200px;
            height: auto;
            width: auto;
        }
        .uk-logo img{
            max-width: 200px;
            max-height: 200px;
            height: auto;
            width: auto;
        }
    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {
        .c_image{
            max-width: 250px;
            max-height: 250px;
            height: auto;
            width: auto;
        }
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
        .c_image{
            max-width: 400px;
            max-height: 400px;
            height: auto;
            width: auto;
        }
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
        .c_image{
            max-width: 300px;
            max-height: 300px;
            height: auto;
            width: auto;
        }
    }
</style>