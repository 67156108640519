<template>
    <div>
        <div class="uk-padding uk-background-muted">
            <div class="uk-container">
                <h2 class="uk-heading-small uk-text-center" style="font-variant: all-small-caps; color: #FAA21B;">About Our Firm</h2>
                <div class="uk-panel">

                    <p style="" class="uk-text-lead">
                        The Fanger Law Firm, PLLC. is a small boutique firm that focuses on matters within the areas of
                        Tax, Immigration, and Family Law. Our top priority is our clients. We strive to provide quality
                        representation by getting to know our clients on a personal level. Our clients’ concerns are our
                        concerns.
                    </p>
                </div>
            </div>
        </div>
        <div class="uk-padding ">
            <div class="uk-container">
                <h1 class="uk-heading-line uk-text-center" style="font-variant: all-small-caps; color: #FAA21B;"><span>Meet Michelle Fanger</span></h1>
                <div class="uk-panel">
                    <img class="uk-align-left uk-margin-remove-adjacent" src="../assets/michelle.jpg" width="280" height="280" alt="Example image">
                    <p>
                        Michelle T. Fanger is the founder of The Fanger Law Firm, PLLC. Michelle is a member of the
                        Florida Bar, and is also licensed to practice before the United States District Court for the Middle
                        District of Florida, and the United States Tax Court. She actively practices before Executive
                        Office for Immigration Review (the Immigration Court and the Board of Immigration Appeals).
                        Michelle has defended both corporations and individuals in numerous complex tax cases
                        involving the Internal Revenue Service (IRS.) Her tax practice incorporates both state and
                        federal regulations, involving counseling and assisting clients on achieving and maintaining
                        compliance with the Tax Code.
                    </p>
                    <p>
                        Michelle has extensive experience on Immigration matters. She has represented clients on
                        matters, including family-based petitions, such as green card, US citizenship, Violence Against
                        Women’s Act (VAWA), and deportation. Her passion for assisting clients with their immigration
                        matters was born out her being an immigrant herself. Before starting her private practice,
                        Michelle’s experience in immigration work began in the Immigrant Rights Clinic while she was
                        still a student at Florida Coastal School of Law. She worked on asylum/refugee and deportation
                        cases, and successfully accomplished relief for clients in removal proceedings. She also gained
                        experience by interning at the renowned Immigration Law Firm, Kurzban Kurzban Weinger
                        Tetzeli & Pratt, P.A.
                    </p>
                    <p>
                        Additionally, Michelle practices in the area of Family Law where she represents clients in time
                        sharing (child custody), child support, alimony, divorce, and marital settlement agreements. She
                        attends mediation on behalf of her clients and negotiates settlement terms that are in the best
                        interest of the child and the client.
                    </p>
                    <p>
                        Michelle serves on the Voluntary Bar Liaison Committee of The Florida Bar. She is a member of
                        the Jacksonville Bar Association,, and the Family Law Section of the Florida Bar. She was a
                        Hearing Officer for the St. Petersburg Housing Authority. She is a co-founder of the Jamaicans
                        United, Inc.
                    </p>
                    <hr class="uk-divider-icon">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>
    h5{
        font-family: 'Cormorant SC', serif !important;
    }
</style>