<template>
    <div class="uk-padding uk-background-muted" style="min-height: 75vh">
        <div >
            <h1 class="uk-text-center  " style=" color: #FAA21B;"><span>Immigration Law</span></h1>
        </div>
        <div class="uk-padding">
            <div class="uk-container">
                <div class="uk-panel">
                    <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="/visionpic.jpg"  alt="Example image">
                  <h4>The areas under immigration law that we cover are: </h4>
                  <ul class="uk-list uk-list-bullet">
                    <li><span class="bluec">Family Based Immigration</span></li>
                    <li><span class="bluec">Adjustment of Status</span></li>
                    <li><span class="bluec">Citizenship and Naturalization</span></li>
                    <li><span class="bluec">Humanitarian Visas (VAWA)</span></li>
                    <li><span class="bluec">Visitors and Counselor Visas</span></li>
                    <li><span class="bluec"><u>Removal Defense</u>, <span class="uk-text-muted uk-text-small"><mark>including Asylum and Cancellation of Removal</mark></span></span></li>
                  </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ImmigrationLaw"
    }
</script>

<style scoped>
    dt{
        color: #093170;

        font-size: large;
    }
    .bluec{
        font-weight: bold;
        font-size: 16px;
        color: #093170;
    }

    @media only screen and (min-width : 320px) {
        img{
            max-width: 280px;
            max-height: 280px;
            width: auto;
            height: auto;
            border-radius: 5px;
        }

    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {

    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
        img{
            max-width: 320px;
            max-height: 320px;
            width: auto;
            height: auto;
        }
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
        img{
            max-width: 400px;
            max-height: 400px;
            width: auto;
            height: auto;
        }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {

    }

    @media only screen and (min-width : 1400px) {

    }
    @media only screen and (min-width : 1600px) {

    }
</style>