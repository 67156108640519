<template>
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="ratio: 10:4; animation: push; autoplay: true">

        <ul class="uk-slideshow-items">
            <li>
                <img src="../../assets/family_law.jpg" alt="" uk-cover>
                <div class="uk-position-medium uk-position-bottom-center uk-text-center uk-light uk-visible@m" >
                    <!--<h2 class="uk-margin-remove">Family Law</h2>
                    <p class="uk-margin-remove uk-visible@m" style="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <br>-->
                    <a href="#services" class=" uk-button uk-button-link  uk-text-capitalize uk-visible@m"  uk-scroll="offset:100"
                       style="text-decoration: none; "><h3 style="color: white !important;">Learn More
                        <br>
                        <span uk-icon="chevron-down"></span></h3></a>
                </div>
            </li>
            <li>
                <img src="../../assets/immigration_law2.jpg" alt="" uk-cover>
                <div class="uk-position-medium uk-position-bottom-center uk-text-center uk-light uk-visible@m">
                    <!--<h2 class="uk-margin-remove">Immigration Law</h2>
                    <p class="uk-margin-remove uk-visible@m" style="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <br>-->
                    <a href="#services" class=" uk-button uk-button-link  uk-text-capitalize uk-visible@m" uk-scroll="offset:100"
                       style="text-decoration: none; "><h3 style="color: white !important;">Learn More <br> <span uk-icon="chevron-down"></span></h3></a>
                </div>
            </li>
            <li>
                <img src="../../assets/tax_law.jpg" alt="" uk-cover>
                <div class="uk-position-medium uk-position-bottom-center uk-text-center uk-light uk-visible@m">
                    <!--<h2 class="uk-margin-remove">Immigration Law</h2>
                    <p class="uk-margin-remove uk-visible@m" style="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <br>-->
                    <a href="#services" class=" uk-button uk-button-link  uk-text-capitalize uk-visible@m" uk-scroll="offset:100"
                       style="text-decoration: none; "><h3 style="color: white !important;">Learn More <br> <span uk-icon="chevron-down"></span></h3></a>
                </div>
            </li>
        </ul>

        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

    </div>
</template>

<script>
    export default {
        name: "carousel"
    }
</script>

<style scoped>

    @media only screen and (min-width : 320px) {
        h2{
            color: white !important;
        }
        /*a{
            color: #093170 !important;
        }*/
    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {

    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {

    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {

    }

    @media only screen and (min-width : 1400px) {

    }
    @media only screen and (min-width : 1600px) {

    }
</style>