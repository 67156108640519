<template>
    <div class="uk-padding uk-background-muted" style="min-height: 70vh">
        <div >
            <h1 class="uk-text-center  " style=" color: #FAA21B;"><span>
            Family Law
        </span></h1>
        </div>

        <div >

            <div class="uk-container">
                <div class="uk-panel">
                    <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="/bg_family.jpg"  alt="Example image">
                  <h4>The areas under family law that we cover are: </h4>
                  <ul class="uk-list uk-list-bullet">
                    <li><span class="bluec">Child Custody/Time-Sharing</span></li>
                    <li><span class="bluec">Child Support</span></li>
                    <li><span class="bluec">Divorce</span></li>
                    <li><span class="bluec">Contempt/ Enforcement Proceedings</span></li>
                    <li><span class="bluec">Modification of Existing Orders</span></li>
                    <li><span class="bluec">Alimony</span></li>
                    <li><span class="bluec">Relocation</span></li>
                    <li><span class="bluec">Paternity</span></li>
                  </ul>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FamilyLaw"
    }
</script>

<style scoped>
    dt{
        color: #093170;

        font-size: large;
    }
    .bluec{
        font-weight: bold;
        font-size: 16px;
        color: #093170;
    }

    @media only screen and (min-width : 320px) {
        img{
            max-width: 280px;
            max-height: 280px;
            width: auto;
            height: auto;
            border-radius: 5px;
        }

    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {

    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
        img{
            max-width: 320px;
            max-height: 320px;
            width: auto;
            height: auto;
        }
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
        img{
            max-width: 400px;
            max-height: 400px;
            width: auto;
            height: auto;
        }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {

    }

    @media only screen and (min-width : 1400px) {

    }
    @media only screen and (min-width : 1600px) {

    }
</style>