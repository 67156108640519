<template>
  <div class="uk-padding">
    <div class="uk-container">
      <h2 class="uk-heading-small uk-text-center" style="font-variant: all-small-caps; color: #FAA21B;">Testimonials</h2>
      <div class="uk-child-width-1-1" uk-grid>
        <div>
          <div class="uk-panel">
            <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="../assets/nappy.jpg" width="225" height="150" alt="Example image">
            <h3>AW - Satisfied client</h3>
            <p>
              The Fanger Law Firm was referred to me by a friend.
              I was having immigration problems and did not know how to get help or who I could trust.
              The Fanger Law Firm did not disappoint me. Ms. Fanger made sure that all deadlines were met and my problem was solved in just a few weeks.
              I would recommend the Fanger Law Firm to anyone who needs a good immigration lawyer.
            </p>
          </div>
          <div class="uk-panel">
            <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="../assets/emmy.jpg" width="225" height="150" alt="Example image">
            <h3>NG - Satisfied client</h3>
            <p>
              I have been working with Michelle Fanger over the years. She prepared my Mom as well as my Wife documents.
              She is reachable, Friendly and Very Reliable. I recommended her to several persons, who were very satisfied with her service.
            </p>
          </div>
          <div class="uk-panel">
            <img class="uk-align-center uk-align-right@m uk-margin-remove-adjacent"  src="../assets/gustavo.jpg" width="225" height="150" alt="Example image">
            <h3>SC - Satisfied client</h3>
            <p>
              My husband and I had a very great experience using Michelle Fanger of The Fanger Law Firm. PLLC. as our immigration lawyer. Even though the process seemed very long and frustrating at times she made it very easy for us. I could pick up my phone and call Michelle inquiring about any development on my case and she would be available to answer. Michelle is very reliable, hardworking, trustworthy and knowledgeable about her job. I would recommend Micelle Fanger to anyone who needs a reliable immigration lawyer. she goes above and beyond to get the job done! Thank you, Michelle, my family and I are very grateful!
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "Testimonials"
}
</script>

<style scoped>
  dl{
    font-size: 16px;
  }
  dt{
    font-size: 20px;
  }
</style>