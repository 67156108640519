import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    contactForm({ state, commit }, payload){

      var data = payload      
      //console.log(data)      
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          axios({
            method: 'post',
            url: 'https://www.fangerlawfirm.com/app/api/email',
            data,
            headers: {
              //'Authorization': 'Bearer ' + payload.token,
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
              .then(function (response) {
                //commit('login')
                resolve(response)
              })
              .catch(function (error) {
                console.log(error);
                reject(error)

              });

        }, 1000)
      })
    },
  },
  modules: {
  }
})
