<template>
  <div class="home">
    <carousel/>
    <our-services/>
    <!--<affiliate-awards-recognition/>-->
    <about-department/>
    <client-testimonials/>
    <five-minutes/>
    <!--<numbers/>-->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Carousel from "../components/home/carousel";
import AffiliateAwardsRecognition from "../components/home/affiliateAwardsRecognition";
import AboutDepartment from "../components/home/aboutDepartment";
import ClientTestimonials from "../components/home/clientTestimonials";
import Numbers from "../components/home/Numbers";
import OurServices from "../components/home/ourServices";
import FiveMinutes from "../components/home/fiveMinutes";

export default {
  name: 'Home',
  components: {
    FiveMinutes,
    OurServices,
    Numbers,
    ClientTestimonials,
    AboutDepartment,
    AffiliateAwardsRecognition,
    Carousel,
    HelloWorld
  }
}
</script>
