<template>
    <div class="uk-padding" uk-scrollspy="cls:uk-animation-fade">
        <h1 class="uk-text-center  uk-heading-line" style="font-variant: all-small-caps; color: #FAA21B;"><span>Client Testimonials</span></h1>
        <div class="uk-container">

            <div uk-slider="finite: true">

                <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                    <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
                        <li>
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-media-top">
                                    <img src="../../assets/nappy.jpg" alt="">
                                </div>
                                <div class="uk-card-body">
                                    <h3 class="uk-card-title">AW</h3>
                                    <p>
                                        The Fanger Law Firm was referred to me by a friend. I was having immigration problems and did not know how to get help or who I could trust...
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-media-top">
                                    <img src="../../assets/emmy.jpg" alt="">
                                </div>
                                <div class="uk-card-body">
                                    <h3 class="uk-card-title">NG</h3>
                                    <p>I have been working with Michelle Fanger over the years. She prepared my Mom's as well as my Wife's documents. She is reachable, Friendly and Very Reliable...</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-media-top">
                                    <img src="../../assets/gustavo.jpg" alt="">
                                </div>
                                <div class="uk-card-body">
                                    <h3 class="uk-card-title">SC</h3>
                                    <p>My husband and I had a very great experience using Michelle Fanger of The Fanger Law Firm. PLLC. as our immigration lawyer. I could pick up my phone and call Michelle...</p>
                                </div>
                            </div>
                        </li>                        
                    </ul>

                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

                </div>

                <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                <br>
            </div>
            <router-link to="/testimonials" class="uk-button uk-button-default uk-align-right@m"
                    style="background-color: #093170; color: white; border-radius: 5px"
            >View More ...</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "clientTestimonials"
    }
</script>

<style scoped>

</style>