<template>
    <div class="uk-padding uk-background-muted" id="aboutFirm" uk-scrollspy="cls:uk-animation-fade">
        <h1 class="uk-text-center  uk-heading-line" style="font-variant: all-small-caps; color: #FAA21B;"><span>
            Our Firm
        </span></h1>

        <div class="uk-container">
            <img class="uk-align-left uk-margin-remove-adjacent" src="../../assets/bg1.jpg"
                 style="max-width: 300px; max-height: 300px; width: auto; height: auto;"
                 alt="Example image">
            <p>
                The Fanger Law Firm, PLLC. is a small boutique firm that focuses on matters within the areas of Tax, Immigration, and Family Law. Our top priority is our clients. We strive to provide quality representation by getting to know our clients on a personal level. Our clients’ concerns are our concerns.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutDepartment"
    }
</script>

<style scoped>
    img{
        max-width: 500px;
        max-height: 500px;
        height: auto;
        width: auto;
    }
</style>