<template>
    <div uk-sticky style="border-bottom: #FAA21B 1px solid; background-color: white !important;">
        <div class="uk-visible@m" style="background-color: #093170 !important; padding: 5px;">
            <div class="uk-container uk-container-large">
                <a href="https://www.linkedin.com/in/michelle-fanger-esquire-71905b1aa/" target="_blank" class="uk-align-right@m uk-margin-remove-bottom" style="font-variant: all-small-caps; font-size: large; color: #c0c0c0" >
                    <span uk-icon="ratio: 0.8; icon: linkedin"></span>
                    Connect on LinkedIn</a>
                <router-link to="/testimonials" class="uk-align-right@m uk-margin-remove-bottom" style="font-variant: all-small-caps; font-size: large; color: #c0c0c0" >
                    Testimonials</router-link>
            </div>
        </div>
        <div class="uk-container uk-container-large" style="background-color: white !important;">
            <nav class="uk-navbar-container uk-margin" uk-navbar style="background-color: white !important;">
                <div class="uk-navbar-left">
                    <a class="uk-navbar-item uk-logo" href="/" >
                        <img src="../../../public/logo_horizontal.png"  alt="">
                    </a>
                </div>
                <div class="uk-navbar-right uk-visible@m">

                    <ul class="uk-navbar-nav">
                        <li><router-link to="/" class="aStyle uk-text-capitalize">Home</router-link></li>
                        <li><router-link to="/about" class="aStyle uk-text-capitalize">About our Firm</router-link></li>

                        <!--<li><router-link to="/practiceareas" class="aStyle">Practice Areas</router-link></li>-->
                        <li>
                            <a href="#" class="aStyle uk-text-capitalize">Practice Areas</a>
                            <div class="uk-navbar-dropdown" style="margin-top: -15px">
                                <ul class="uk-nav uk-navbar-dropdown-nav">
                                    <li class="uk-active"><router-link to="/family_law">Family Law</router-link></li>
                                    <li><router-link to="/immigration_law">Immigration Law</router-link></li>
                                    <li><router-link to="/tax_law">Tax Law</router-link></li>

                                </ul>
                            </div>
                        </li>
                        <!-- <li><router-link to="/peopleandcommunity" class="aStyle uk-text-capitalize">People and Community</router-link></li> -->
                        <li><router-link to="/contactus" class="aStyle uk-text-capitalize">Contact Us</router-link></li>
                    </ul>
                </div>
                <div class="uk-navbar-right uk-hidden@m">
                    <a class="uk-button uk-button-link" href="javascript:" uk-toggle="target: #offcanvas-nav">
                        <span uk-icon="icon: menu; ratio: 1.5"></span>
                    </a>
                    <div id="offcanvas-nav" uk-offcanvas="overlay: true">

                        <div class="uk-offcanvas-bar">
                            <div class="uk-navbar-left">
                                <a class="uk-navbar-item uk-logo" href="#" >
                                    <img src="../../../public/logo_horizontal.png"  alt="">
                                </a>

                            </div>
                            <ul class="uk-nav uk-nav-default uk-nav-parent-icon" uk-nav>
                                <li><router-link to="/" class="aStyle">Home</router-link></li>
                                <li><router-link to="/about" class="aStyle">About our Firm</router-link></li>

                                <!--<li><router-link to="/practiceareas" class="aStyle">Practice Areas</router-link></li>-->
                                <!--<li>
                                    <a href="#" class="aStyle">Practice Areas</a>
                                    <div class="uk-navbar-dropdown" style="margin-top: -15px">
                                        <ul class="uk-nav uk-navbar-dropdown-nav">
                                            <li class=""><router-link to="/family_law">Family Law</router-link></li>
                                            <li><router-link to="/immigration_law">Immigration Law</router-link></li>
                                            <li><router-link to="/tax_law">Tax Law</router-link></li>

                                        </ul>
                                    </div>
                                </li>-->
                                <li class="uk-parent">
                                    <a href="#" class="aStyle">Practice Areas</a>
                                    <ul class="uk-nav-sub">
                                        <li class="uk-active"><router-link to="/family_law">Family Law</router-link></li>
                                        <li><router-link to="/immigration_law">Immigration Law</router-link></li>
                                        <li><router-link to="/tax_law">Tax Law</router-link></li>
                                    </ul>
                                </li>
                                <li><router-link to="/peopleandcommunity" class="aStyle">People and Community</router-link></li>
                                <li><router-link to="/contactus" class="aStyle">Contact Us</router-link></li>
                            </ul>

                        </div>
                    </div>

                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "navbar"
    }
</script>

<style scoped>
.aStyle{
    color: #093170 ;
    font-weight: bold;

}
.uk-logo{
    color: #FAA21B !important;
}
@media only screen and (min-width : 320px) {
.uk-logo img{
    max-width: 150px;
    max-height: 150px;
    height: auto;
    width: auto;
}
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

@media only screen and (min-width : 1400px) {

}
@media only screen and (min-width : 1600px) {

}
</style>