<template>
    <div>
        <div class="uk-padding uk-background-muted">
            <div class="uk-container">
                <h2 class="uk-heading-small uk-text-center" style="font-variant: all-small-caps; color: #FAA21B;">People & Community</h2>
                <div class="uk-child-width-1-3@m" uk-grid>
                    <div><img src="../assets/charleswundengba.jpg" alt=""></div>
                    <div><img src="../assets/pixabay.jpg" alt=""></div>
                    <div><img src="../assets/lukas.jpg" alt=""></div>
                </div>
                <p class="uk-text-large">
                    Statement of Commitment to help persons through various initiative and outreach programs.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                </p>
            </div>
        </div>
        <div class="uk-padding ">

            <div class="uk-container uk-container-xsmall">
                <h2 class="uk-heading-bullet">Fanger Law in the Community</h2>
                <dl class="uk-description-list uk-description-list-divider">
                    <dt>Community Work 1</dt>
                    <dd>
                        <div>
                            <br>
                            <img src="../assets/bg1.jpg" alt="">
                            <p class="uk-text-small">
                                <br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </dd>
                    <dt>Community Work 2</dt>
                    <dd>
                        <div>
                            <br>
                            <img src="../assets/bg1.jpg" alt="">
                            <p class="uk-text-small">
                                <br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </dd>
                    <dt>Community Work 3</dt>
                    <dd>
                        <div>
                            <br>
                            <img src="../assets/bg1.jpg" alt="">
                            <p class="uk-text-small">
                                <br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </dd>
                    <dt>Community Work 4</dt>
                    <dd>
                        <div>
                            <br>
                            <img src="../assets/bg1.jpg" alt="">
                            <p class="uk-text-small">
                                <br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </dd>
                    <dt>Community Work 5</dt>
                    <dd>
                        <div>
                            <br>
                            <img src="../assets/bg1.jpg" alt="">
                            <p class="uk-text-small">
                                <br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </dd>

                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PeopleCommunity"
    }
</script>

<style scoped>
    img{
        border-radius: 15px;
    }
    dt{
        color: #FAA21B;
        font-weight: bold;
    }
</style>